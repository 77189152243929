(function ($) {
    "use strict"; // Start of use strict

    // Offset for Main Navigation
    $("#mainNav").affix({
        offset: {
            top: 100,
        },
    });

    /* EU-COOKIE
    ================================================================================ */
    var eleu = jQuery("#trurnit-eucookie");
    var buttoneu = jQuery("#trurnit-eucookie button");
    if (-1 === document.cookie.indexOf("trurnit-eucookie")) {
        setTimeout(function () {
            eleu.slideDown("slow");
        }, 1000);
    }
    buttoneu.on("click", function () {
        var date = new Date();
        date.setDate(date.getDate() + 365),
            (document.cookie =
                "trurnit-eucookie=" +
                escape("hide") +
                ";path=/;expires=" +
                date.toGMTString());
        eleu.slideUp("slow");
    });

    /* Parallax Effect
    ================================================================================ */
    $(window).on("load scroll", function () {
        var scrolled = $(this).scrollTop();
        $(".hero-img.parallax").css(
            "background-position",
            "50% " + scrolled / 2 + "px"
        );
        $(".intro-text.fadeOut").css({
            transform: "translate3d(0, " + -(scrolled * 0.2) + "px, 0)", // parallax (20% scroll rate)
            //'opacity': 1 - scrolled / 300 // fade out at 400px from top
        });
        $(".scroll-down.fadeOut").css({
            transform: "translate3d(0, " + -(scrolled * 0.2) + "px, 0)", // parallax (20% scroll rate)
            opacity: 1 - scrolled / 300, // fade out at 400px from top
        });
        $(".indicator.fadeOut").css({
            transform: "translate3d(0, " + -(scrolled * 0.2) + "px, 0)", // parallax (20% scroll rate)
            opacity: 1 - scrolled / 300, // fade out at 400px from top
        });
    });

    /*  Accordion
    ================================================================================ */

    var CLASS_ACTIVE = "active";
    var CLASS_OPEN = "open";
    var $accordion = $(".js-accordion");

    $accordion.on("click", ".js-toggle", function (e) {
        var $item = $(e.currentTarget).parent();
        var $content = $item.find(".js-content");
        var isOpen = $item.hasClass(CLASS_OPEN);

        if (isOpen) {
            // close:
            $item.removeClass(CLASS_ACTIVE);
            $content.slideUp(function () {
                $item.removeClass(CLASS_OPEN);
                $content.show();
            });
        } else {
            // open:
            $item.addClass(CLASS_ACTIVE + " " + CLASS_OPEN);
            $content.hide().slideDown();
        }
    });

    /* Magnific Popup
    ================================================================================ */
    $('.inline-popups').magnificPopup({
        delegate: 'a',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });


    /* Replace all SVG images with inline SVG
    ================================================================================ */
    jQuery("img.svg").each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr("id");
        var imgClass = $img.attr("class");
        var imgURL = $img.attr("src");

        jQuery.get(
            imgURL,
            function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find("svg");

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== "undefined") {
                    $svg = $svg.attr("id", imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== "undefined") {
                    $svg = $svg.attr("class", imgClass + " replaced-svg");
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr("xmlns:a");

                // Replace image with new SVG
                $img.replaceWith($svg);
            },
            "xml"
        );
    });

    // /* Move background perspective on mouse move effect
    // ================================================== */

    // var lFollowX = 0,
    //     lFollowY = 0,
    //     x = 0,
    //     y = 0,
    //     friction = 1 / 30;

    // function moveBackground() {
    //     x += (lFollowX - x) * friction;
    //     y += (lFollowY - y) * friction;

    //     var translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

    //     $('.hero-bg').css({
    //         '-webit-transform': translate,
    //         '-moz-transform': translate,
    //         'transform': translate
    //     });

    //     window.requestAnimationFrame(moveBackground);
    // }

    // $(window).on('mousemove click', function(e) {

    //     var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
    //     var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
    //     lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
    //     lFollowY = (10 * lMouseY) / 100;

    // });

    // moveBackground();

    /* Match height
    ================================================== */
    // $(".teaser-section").matchHeight({
    //     byRow: true,
    //     property: 'height',
    //     target: null,
    //     remove: false
    // });

    // /* colio plugin
    // ================================================== */
    /* colio plugin
    ================================================== */
    // var colio_run = function () {
    //     $("#reporting-angebote .wrap").colio({
    //         id: "card-expand",
    //         theme: "white",
    //         placement: "after",
    //         expandLink: ".card-expand-link",
    //         scrollOffset: 250,
    //         syncScroll: true,
    //     });
    // };

    // colio_run();

    /* Testimonial Slider
    ================================================================================ */
    $(".slider-testimonial").slick({
        dots: true,
        arrows: true,
        centerMode: true,
        autoplay: true,
        pauseOnHover: true,
        autoplaySpeed: 10000,
        focusOnSelect: false,
        infinite: true,
        fade: true,
        adaptiveHeight: false,
        //centerPadding: '10px',
    });

    $(".slider-testimonial").on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
            $(".slider-testimonial .slick-dots li").removeClass("slick-active");
            $(".slider-testimonial .slick-dots li button")
                .attr("aria-pressed", "false")
                .focus(function () {
                    this.blur();
                });
        }
    );

    /* Referenzen Slider
    ================================================================================ */
    $(".slider-referenzen").slick({
        dots: true,
        infinite: true,
        touchThreshold: 100,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: true,
        nextArrow:
            '<button class="slick-next"><i class="fas fa-chevron-right"></i></button>',
        prevArrow:
            '<button class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });

})(jQuery); // End of use strict

/* Close Video Overlay
================================================================================ */
$(document).keyup(function (e) {
    if (e.keyCode === 27) {
        close_video();
    }
});

function close_video() {
    $(".video-overlay.open").removeClass("open");
    //$('.video-overlay video').get(0).pause();
    //$('.video-overlay video').remove();
    $(".video-overlay iframe").remove();
    $(".video-overlay video").remove();
    $("body").removeClass("layered");
}
